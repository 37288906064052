import React from "react";
import Container from "./container";
import { cn } from "../lib/helpers";
import PortableText from "./portableText";

import FeaturedBlocks from "./fc-featured-blocks";

import * as styles from "../styles/components/careers-content-block.module.css";

const ContentBlock = (props) => {

  const {
    title,
    _rawDescription,
    featuredBlocks
  } = props;

  return (
    <div className={styles.root}>
      <Container>
        {title && (<h2 className={styles.title}>{title}</h2>)}
        {_rawDescription && (<div className={cn("entry-content", styles.content)}><PortableText blocks={_rawDescription} /></div>)}
      </Container>
      {featuredBlocks && featuredBlocks.length > 0 && (<FeaturedBlocks items={featuredBlocks} />)}
    </div>
  );
}

export default ContentBlock;
