import React from "react";
import FlexibleContent from "../components/flexible-content";

import * as styles from "../styles/components/careers-main.module.css";

const CareersMain = (props) => {

  const {
    content,
  } = props;

  return (
    <div className={styles.root}>
      {content && (<FlexibleContent items={content} />)}
    </div>
  );
}

export default CareersMain;
