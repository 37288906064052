import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";
import Layout from "../containers/layout";
import { Helmet } from 'react-helmet';
import { toPlainText } from "../lib/helpers";

import PageHeader from "../components/careers-landing-page-header";
import CareersMain from "../components/careers-main";
import Positions from "../components/careers-positions";
import ContentBlock from "../components/careers-content-block";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query CareersLandingQuery {
    careersLanding: sanityCareersLanding {
      title
      themePurple
      subtitle
      _rawDescription
      flexibleContent {
        ... on SanityFcFeaturedBlocks {
          _key
          _type
          featuredBlocks {
            _key
            title
            _rawContent
            link {
              blank
              text
              url
            }
            image {
              ...SanityImage
              alt
            }
            switchOrder
          }
        }
        ... on SanityFcRegularContent {
          _key
          _type
          title
          _rawContent
          image {
            ...SanityImage
            alt
          }
        }
        ... on SanityFcMultiColumnContent {
          _key
          _type
          title
          content {
            _key
            icon {
              asset {
                _id
              }
            }
            title
            _rawContent
            link {
              blank
              text
              url
            }
          }
          themeColorBg
          centerAligned
          alternativeLayout
        }
      }
      positions {
        title
        _rawDescription
        positions {
          _key
          career {
            title
            slug {
              current
            }
            location {
              title
            }
            type {
              title
            }
          }
        }
      }
      additionalContent {
        title
        _rawDescription
        featuredBlocks {
          _key
          title
          _rawContent
          link {
            blank
            text
            url
          }
          image {
            ...SanityImage
            alt
          }
          switchOrder
        }
      }
      seo {
        title
        description
        thumbnail {
          asset {
            _id
          }
          alt
        }
      }
    }
  }
`;

const CareersLandingPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const careersLanding = (data || {}).careersLanding;

  const title = careersLanding.seo?.title || careersLanding.title;
  const description = careersLanding.seo?.description || toPlainText(careersLanding._rawDescription);
  const image = careersLanding.seo?.thumbnail;
  const autoSiteTitle = !careersLanding.seo?.title;

  return (
    <Layout>
      <Seo
        title={title}
        description={description}
        image={image}
        autoSiteTitle={autoSiteTitle}
      />

      <Helmet>
        <html className={careersLanding.themePurple ? "theme-purple" : undefined} lang="en" />
      </Helmet>

      {careersLanding.title && (<PageHeader title={careersLanding.title} subtitle={careersLanding.subtitle} description={careersLanding._rawDescription} />)}
      {careersLanding.flexibleContent && (<CareersMain content={careersLanding.flexibleContent} />)}
      {careersLanding.positions && (<Positions {...careersLanding.positions} />)}
      {careersLanding.additionalContent && (<ContentBlock {...careersLanding.additionalContent} />)}
    </Layout>
  );
};

export default CareersLandingPage;
