import React from "react";
import { Link } from "gatsby";
import Container from "./container";
import { cn } from "../lib/helpers";
import PortableText from "./portableText";
import { getCareerUrl } from "../lib/helpers";

import * as styles from "../styles/components/careers-positions.module.css";

const Positions = (props) => {

  const {
    title,
    _rawDescription,
    positions
  } = props;

  return (
    <div className={styles.root} id="positions">
      <Container>
        {title && (<h2 className={styles.title}>{title}</h2>)}
        {_rawDescription && (<div className={cn("entry-content", styles.content)}><PortableText blocks={_rawDescription} /></div>)}
      </Container>
      {positions && positions.length > 0 && (
        <div className={styles.positions}>
          <div className={styles.hRow}>
            <Container>
              <div className="grid align-center">
                <div className={cn("col-8", styles.col)}>
                  <div>Locations</div>
                </div>
                <div className={cn("col-4", styles.col)}>
                  <div>Location</div>
                </div>
              </div>
            </Container>
          </div>
          {positions.map(({ career, _key }) => {
            return (
              <div className={styles.cRow} key={_key}>
                <Container>
                  <div className="grid align-center">
                    <div className={cn("col-8", styles.col)}>
                      {career.type && career.type.title && (<div className={styles.jobType}>{career.type.title}</div>)}
                      {career.title && (<h3 className={cn("h4", styles.jobTitle)}>{career.title}</h3>)}
                    </div>
                    <div className={cn("col-4", styles.col)}>
                      {career.location && career.location.title && (<div className={styles.jobLocation}>{career.location.title}</div>)}
                      <Link to={getCareerUrl(career.slug)} className={styles.link}><span className="screen-reader-text">Read more</span></Link>
                    </div>
                  </div>
                </Container>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default Positions;
